import { render, staticRenderFns } from "./facility-link-child-dialog.vue?vue&type=template&id=bb2b7602"
import script from "./facility-link-child-dialog.vue?vue&type=script&lang=ts"
export * from "./facility-link-child-dialog.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/jenkins_home/workspace/test-物联业务中台(test-nimbus_web_bi)/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('bb2b7602')) {
      api.createRecord('bb2b7602', component.options)
    } else {
      api.reload('bb2b7602', component.options)
    }
    module.hot.accept("./facility-link-child-dialog.vue?vue&type=template&id=bb2b7602", function () {
      api.rerender('bb2b7602', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/dashboard/facility/facility-link-child-dialog.vue"
export default component.exports